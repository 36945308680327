<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id，zb_user.id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id，zb_user.id" />
      </a-form-model-item>
      <a-form-model-item label="最小年龄" prop="minAge" >
        <a-input v-model="form.minAge" placeholder="请输入最小年龄" />
      </a-form-model-item>
      <a-form-model-item label="最大年龄" prop="maxAge" >
        <a-input v-model="form.maxAge" placeholder="请输入最大年龄" />
      </a-form-model-item>
      <a-form-model-item label="婚姻状态id，多个以英文逗分割,sys_dict_data.id" prop="marriageId" >
        <a-input v-model="form.marriageId" placeholder="请输入婚姻状态id，多个以英文逗分割,sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="最低身高" prop="minHeight" >
        <a-input v-model="form.minHeight" placeholder="请输入最低身高" />
      </a-form-model-item>
      <a-form-model-item label="最高身高" prop="maxHeight" >
        <a-input v-model="form.maxHeight" placeholder="请输入最高身高" />
      </a-form-model-item>
      <a-form-model-item label="最低体重" prop="minWeight" >
        <a-input v-model="form.minWeight" placeholder="请输入最低体重" />
      </a-form-model-item>
      <a-form-model-item label="最高体重" prop="maxWeight" >
        <a-input v-model="form.maxWeight" placeholder="请输入最高体重" />
      </a-form-model-item>
      <a-form-model-item label="学历id，多个以英文逗分割，sys_dict_data.id" prop="educationId" >
        <a-input v-model="form.educationId" placeholder="请输入学历id，多个以英文逗分割，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="月收入id，多个以英文逗分割，sys_dict_data.id" prop="incomeId" >
        <a-input v-model="form.incomeId" placeholder="请输入月收入id，多个以英文逗分割，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="月收入" prop="incomeValue" >
        <a-input v-model="form.incomeValue" placeholder="请输入月收入" />
      </a-form-model-item>
      <a-form-model-item label="职业id，多个以英文逗分割，sys_dict_data.id" prop="careerId" >
        <a-input v-model="form.careerId" placeholder="请输入职业id，多个以英文逗分割，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="现居地" prop="currentAddress" >
        <a-input v-model="form.currentAddress" placeholder="请输入现居地" />
      </a-form-model-item>
      <a-form-model-item label="是否要孩子id，多个以英文逗分割，sys_dict_data.id" prop="exceptChildId" >
        <a-input v-model="form.exceptChildId" placeholder="请输入是否要孩子id，多个以英文逗分割，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="吸烟id，多个以英文逗分割，sys_dict_data.id" prop="smokeIds" >
        <a-input v-model="form.smokeIds" placeholder="请输入吸烟id，多个以英文逗分割，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="喝酒id，多个以英文逗分割，sys_dict_data.id" prop="drinkIds" >
        <a-input v-model="form.drinkIds" placeholder="请输入喝酒id，多个以英文逗分割，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="是否有小孩,0-否 1-是" prop="isHaveSon" >
        <a-input v-model="form.isHaveSon" placeholder="请输入是否有小孩,0-否 1-是" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getStandardInfo, addStandardInfo, updateStandardInfo } from '@/api/user/standardInfo'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        minAge: null,

        maxAge: null,

        marriageId: null,

        minHeight: null,

        maxHeight: null,

        minWeight: null,

        maxWeight: null,

        educationId: null,

        incomeId: null,

        incomeValue: null,

        careerId: null,

        currentAddress: null,

        exceptChildId: null,

        smokeIds: null,

        drinkIds: null,

        isHaveSon: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id，zb_user.id不能为空', trigger: 'blur' }
        ],
        minAge: [
          { required: true, message: '最小年龄不能为空', trigger: 'blur' }
        ],
        maxAge: [
          { required: true, message: '最大年龄不能为空', trigger: 'blur' }
        ],
        minHeight: [
          { required: true, message: '最低身高不能为空', trigger: 'blur' }
        ],
        maxHeight: [
          { required: true, message: '最高身高不能为空', trigger: 'blur' }
        ],
        minWeight: [
          { required: true, message: '最低体重不能为空', trigger: 'blur' }
        ],
        maxWeight: [
          { required: true, message: '最高体重不能为空', trigger: 'blur' }
        ],
        isHaveSon: [
          { required: true, message: '是否有小孩,0-否 1-是不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        minAge: null,
        maxAge: null,
        marriageId: null,
        minHeight: null,
        maxHeight: null,
        minWeight: null,
        maxWeight: null,
        educationId: null,
        incomeId: null,
        incomeValue: null,
        careerId: null,
        currentAddress: null,
        exceptChildId: null,
        smokeIds: null,
        drinkIds: null,
        isHaveSon: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getStandardInfo({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateStandardInfo(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addStandardInfo(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
